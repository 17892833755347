import useForm from '@helpers/useForm'
import ContentButtons from '@solid-ui-components/ContentButtons'
import { FormContext } from '@solid-ui-components/ContentForm'
import FormCheckbox from '@solid-ui-components/ContentForm/FormCheckbox'
import FormHidden from '@solid-ui-components/ContentForm/FormHidden'
import FormInput from '@solid-ui-components/ContentForm/FormInput'
import FormTextarea from '@solid-ui-components/ContentForm/FormTextarea'
import Reveal from '@solid-ui-components/Reveal'
import PropTypes from 'prop-types'
import React, { useContext, useEffect } from 'react'
import { BiCheckCircle, BiErrorCircle } from 'react-icons/bi'
import { MdClose } from "react-icons/md"
import { Box, css, Spinner } from 'theme-ui'
const styles = {
  form: {
    position: `relative`
  },
  responseOverlay: {
    position: `absolute`,
    backgroundColor: `transparent`,
    width: `full`,
    height: `105%`,
    transition: `background-color 350ms ease-in`,
    textAlign: `center`,
    zIndex: -1,
    p: 3,
    top: 0,
    left: 0,
    active: {
      zIndex: 0,
      backgroundColor: `rgba(255,255,255,0.85)`
    }
  },
  buttonsWrapper: {
    display: `inline-flex`,
    flexWrap: `wrap`,
    justifyContent: `center`,
    '.button-group-button + .button-group-link': {
      flex: `100%`,
      ml: 0,
      mt: 3
    }
  }
}

const ContentForm = ({ id, form: { action, fields, buttons } = {} }) => {
  const { handleSubmit, submitting, success, reset, errorMessage, closePopup, successMessage } = useForm()
  const { formValues, setFormValues } = useContext(FormContext)
  const formId = id

  useEffect(() => {
    return () => {
      // success !== undefined &&
      // submitting === false &&
      // setFormValues({
      //   ...formValues,
      //   [formId]: {}
      // })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [success, submitting, setFormValues])

  const onChange = e => {
    setFormValues({
      ...formValues,
      [formId]: {
        ...formValues?.[formId],
        [e.target.name]: e.target.value
      }
    })
  }

  const onSubmit = e => {
    e.preventDefault();

    if(formId == 'form.authentication.0'){
      const params = new URLSearchParams()
      params.append("email",formValues['form.authentication.0']['email']);
      params.append("password",formValues['form.authentication.0']['password']);

      handleSubmit(e, { values: params, action })

    } else if(formId == 'form.authentication.1'){
      var entry = {
        name:formValues['form.authentication.1']['name'],
        email:formValues['form.authentication.1']['email'],
        time:new Date().toISOString()
      };
      handleSubmit(e, { values: formValues[formId], action })
    }
    else if(formId == 'form.contact.0'){
      var entry = {
        name:formValues['form.contact.0']['name'],
        email:formValues['form.contact.0']['email'],
        subject:formValues['form.contact.0']['subject'],
        message:formValues['form.contact.0']['message'],
        time:new Date().toISOString()
      };
      handleSubmit(e, { values: formValues[formId], action })
    }
  }

  return (
    <form
      css={css(styles.form)}
      method='POST'
      onSubmit={onSubmit}
      // action="http://localhost:3000/contactus"
      demo={action ? undefined : 'demo'}
    >
      <Box variant='forms.row'>
        {fields?.map(({ identifier, value, ...props }, index) => {
          let Component
          switch (props.type) {
            case 'PASSWORD':
            case 'EMAIL':
            case 'TEXT':
              Component = FormInput
              break
            case 'TEXTAREA':
              Component = FormTextarea
              break
            case 'CHECKBOX':
              Component = FormCheckbox
              break
            case 'HIDDEN':
              Component = FormHidden
              break
            default:
              break
          }

          return (
            <Box
              key={`index-${index}`}
              variant={props.compact ? 'forms.compact' : 'forms.full'}
            >
              <Component
                {...props}
                onChange={onChange}
                name={identifier}
                id={`${formId}.${identifier}`}
                value={formValues?.[formId]?.[identifier] || value || undefined}
              />
            </Box>
          )
        })}
      </Box>
      <Box sx={{ textAlign: `center` }}>
        <ContentButtons
          content={buttons}
          wrapperStyles={styles.buttonsWrapper}
          space={1}
        />
      </Box>
      <Box
        sx={styles.responseOverlay}
        css={(submitting || (typeof success !== 'undefined')) && styles.responseOverlay.active}
      >
        {submitting && (
          <Reveal effect='fadeInDown'>
            <Spinner size='64' color='alpha' />
          </Reveal>
        )}
        {success === true && (
          <>
          <Reveal effect='fadeInDown'>
            <BiCheckCircle size='64' css={css({ color: `success` })} />
          </Reveal>
            <p css={css({ color: `success`, "fontWeight": "bold", })}>
              {successMessage}
            </p>
          </>
        )}
        {success === false && (
          <>
          <MdClose
          css={{
            "color": "#f56565",
            "position": "absolute",
            "right": "10%",
            "top": "-5%",
            "fontSize": "20px",
            "fontWeight": "bold",
            "cursor": "pointer"
          }}
          onClick={() => {
            closePopup();
          }}/>
          <Reveal effect='fadeInDown'>
            <BiErrorCircle size='64' css={css({ color: `error` })} />
          </Reveal>
          <p css={css({ color: `error` })}>
            {errorMessage}
          </p>
         </>
        )}
      </Box>
    </form>
  )
}

export default ContentForm

ContentForm.propTypes = {
  handleSubmit: PropTypes.func,
  submitting: PropTypes.bool,
  success: PropTypes.bool
}
