import { useCallback, useEffect, useState } from 'react'

const useForm = () => {
  const [submitting, setSubmitting] = useState(false)
  const [target, setTarget] = useState({})
  const [success, setSuccess] = useState()
  const [values, setValues] = useState()
  const [action, setAction] = useState()
  const [errorMessage, setErrorMessage] = useState("")
  const [successMessage, setSuccessMessage] = useState("")

  const handleSubmit = (e, { values, action }) => {
    e.preventDefault()
    if (values) setValues(values)
    if (action) setAction(action)
    setTarget(e.target)
    setSubmitting(true)
  }

  const sendValues = useCallback(() => {
    const form = new FormData(target)
    const formData = new URLSearchParams(values || form).toString()

    // Real form submission


    const actionUrl = action || target.action;
    fetch(actionUrl, {
      method: target.method || 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        Accept: 'application/json'
      },
      body: formData
    })
      .then((response) => {
        if (action === "https://app.rhodium11.com/gatsby-login"){
            if(response.status >= 500){
              setErrorMessage(`Login error: Please try again, login at https://app.rhodium11.com/login or contact support.`)
              setSuccess(false);
            } else if (response.status >= 400){
              setErrorMessage(`Login failed. Please try again, reset your password or contact support.`)

              setSuccess(false);
            } else if(response.status >= 300){
              setSuccess(false);
              setErrorMessage(`Login error: Please try again, login at https://app.rhodium11.com/login or contact support.`)
            }else {
              (async() => {const data = await response.json()
              setSuccess(true);
              window.location = `https://app.rhodium11.com/gatsby-login?creds=${data?.data}`
              target.reset()
              })()
            }
        }

        // http://email.rhodium11.com/waitlist
        if (action === "https://email.rhodium11.com/waitlist"){
          setSuccess(true);
        }

        // Handle contact us page
        if (action === "https://email.rhodium11.com/contactus") {
          if (response.status >= 500) {
            setErrorMessage(`Something went wrong, please try again later.`)
            setSuccess(false);
          } else if (response.status >= 400) {
            setSuccessMessage(`Thanks. We’ll get back to you as soon as we can.`)
            setSuccess(true);
          } else {
            setSuccessMessage("Thanks. We’ll get back to you as soon as we can.");
            setSuccess(true);
          }
        }
      })
      .catch(error => {
        if (action === "https://app.rhodium11.com/gatsby-login"){
          setErrorMessage(`Login error: Please try again, login at https://app.rhodium11.com/login or contact support.`)
        } else if (action === "https://email.rhodium11.com/contactus") {
          if (response.status >= 500) {
            setErrorMessage(`Something went wrong, please try again later.`)
            setSuccess(false);
          } else if (response.status >= 400) {
            setSuccessMessage(`Thanks. We’ll get back to you as soon as we can.`)
            setSuccess(true);
          }
        } else {
          setSuccess(false)
        }
      })
      .finally(() => {
        setSubmitting(false)
      })
  }, [target,values])

  useEffect(() => {
    if (submitting) {
      sendValues()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submitting, sendValues])

  const reset = useCallback(() => {
    setSubmitting(false)
    setSuccess(undefined)
    setValues(undefined)
    setAction(undefined)
  }, [])


  const closePopup = useCallback(() => {
    setSubmitting(false)
    setSuccess(undefined)
  }, [])


  return {
    handleSubmit,
    submitting,
    success,
    reset,
    errorMessage,
    successMessage,
    closePopup
  }
}

export default useForm
